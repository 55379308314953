import { Event, NavigationEnd, Scroll } from '@angular/router';
import { filter, map, pipe } from 'rxjs';

export const isNavigationEnd = () => {
	return pipe(
		filter((event: Event) => {
			const isNavigationEnd = event instanceof NavigationEnd;
			const isScroll = event instanceof Scroll;

			return isNavigationEnd || (isScroll && event.routerEvent instanceof NavigationEnd);
		}),
		map((event: Event) => {
			if (event instanceof NavigationEnd) {
				return event;
			}

			return (event as Scroll).routerEvent as NavigationEnd;
		}),
	);
};
