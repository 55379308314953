import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { isNavigationEnd } from '@utils/routing/is-navigation-end';
import { Subscription } from 'rxjs';

import { MODAL_COMPONENTS } from './modal.constants';
import { ModalComponent, ModalTypes } from './modal.types';

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	private subscriptions$: Subscription[] = [];

	dialog = inject(MatDialog);
	router = inject(Router);

	dialogRef?: MatDialogRef<unknown>;
	components: ModalComponent = MODAL_COMPONENTS;

	constructor() {
		this.router.events.pipe(isNavigationEnd()).subscribe(() => {
			this.close();
		});
	}

	async getComponent(component: ModalTypes) {
		return await this.components[component];
	}

	async open(type: ModalTypes, data?: unknown, config?: MatDialogConfig) {
		const component = await this.getComponent(type);

		// Removes focus on app before opening modal
		// to prevent issue of modal being opened on top of app
		// https:stackoverflow.com/questions/79159883/warning-blocked-aria-hidden-on-an-element-because-its-descendant-retained-focu
		const buttonElement = document.activeElement as HTMLElement;
		buttonElement.blur();

		this.close();
		this.dialogRef = this.dialog.open(component, {
			closeOnNavigation: true,
			disableClose: true,
			autoFocus: 'dialog',
			...(config || {}),
			data,
		});
	}

	afterClose(callback?: (value: any) => void) {
		const afterCloseSubscription$ = this.dialogRef?.afterClosed().subscribe(callback);
		this.subscriptions$.push(afterCloseSubscription$ as Subscription);
	}

	close(result?: unknown) {
		this.dialogRef?.close(result);
		this.dialog.closeAll();
		this.dialogRef = undefined;

		this.subscriptions$.forEach((subscription) => subscription?.unsubscribe());
	}
}
