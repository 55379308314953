import { ModalComponent } from './modal.types';

export const MODAL_COMPONENTS: ModalComponent = {
	'add-link': import(`./add-link/add-link.component`).then(
		({ AddLinkComponent }) => AddLinkComponent,
	),
	'add-policy': import(`./add-policy/add-policy.component`).then(
		({ AddPolicyComponent }) => AddPolicyComponent,
	),
	'copy-device': import(`./copy-device/copy-device.component`).then(
		({ CopyDeviceComponent }) => CopyDeviceComponent,
	),
	'delete-device': import(`./delete-device/delete-device.component`).then(
		({ DeleteDeviceComponent }) => DeleteDeviceComponent,
	),
	'delete-policy': import(`./delete-policy/delete-policy.component`).then(
		({ DeletePolicyComponent }) => DeletePolicyComponent,
	),
	'device-subscription': import(`./device-subscription/device-subscription.component`).then(
		({ DeviceSubscriptionComponent }) => DeviceSubscriptionComponent,
	),
	'device-timer': import(`./device-timer/device-timer.component`).then(
		({ DeviceTimerComponent }) => DeviceTimerComponent,
	),
	'import-device': import(`./import-device/import-device.component`).then(
		({ ImportDeviceComponent }) => ImportDeviceComponent,
	),
	'internet-exceptions': import(`./internet-exceptions/internet-exceptions.component`).then(
		({ InternetExceptionsComponent }) => InternetExceptionsComponent,
	),
	'remove-link': import(`./remove-link/remove-link.component`).then(
		({ RemoveLinkComponent }) => RemoveLinkComponent,
	),
	'session-confirm': import(`./session-confirm/session-confirm.component`).then(
		({ SessionConfirmComponent }) => SessionConfirmComponent,
	),
	'user-add': import(`./user-add/user-add.component`).then(
		({ UserAddComponent }) => UserAddComponent,
	),
	'user-remove': import(`./user-remove/user-remove.component`).then(
		({ UserRemoveComponent }) => UserRemoveComponent,
	),
};
